import _taggedTemplateLiteral from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject;

import styled from 'styled-components';
import { sizes } from '../theme/sizes';
import { colors } from '../theme/colors';
export var Title = styled.h1(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-size: ", ";\n  line-height: ", ";\n  color: ", ";\n  font-weight: bold;\n  font-style: normal;\n  text-transform: ", ";\n  text-align: ", ";\n\n\n  @media screen and (max-width: ", ") {\n    text-align: ", ";\n  }\n"])), function (props) {
  return props.fontSize ? props.fontSize : sizes.titleFontSize;
}, function (props) {
  return props.lineHeight ? props.lineHeight : sizes.titleLineHeight;
}, function (props) {
  return props.color ? props.color : colors.titleForeground;
}, function (props) {
  var _props$transform;

  return (_props$transform = props.transform) !== null && _props$transform !== void 0 ? _props$transform : 'inherit';
}, function (props) {
  return props.center ? 'center' : 'inherit';
}, sizes.maxWidthMobile, function (props) {
  return props.center ? 'center' : 'start';
});