export var sizes = {
  pageContainerPadding: '20px',
  dividerSize: '1px',
  maxWidthMobile: '630px',
  maxWidthMobileNumber: 630,
  // button
  buttonDefaultPadding: '10px 26px',
  // text
  titleFontSize: '16px',
  titleLineHeight: '20px',
  textFontSize: '14px',
  textLineHeight: '17px',
  descriptionFontSize: '12px',
  descriptionLineHeight: '15px',
  // spaces
  defaultHorizontalSpace: '10px',
  emojiRattingSelectedSize: '46px'
};