import _taggedTemplateLiteral from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject;

import styled from 'styled-components';
export var ListSpacer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  > :first-child {\n    margin-top: ", ";\n  }\n\n  > :last-child {\n    margin-bottom: ", ";\n  }\n\n  > :not(:last-child) {\n    margin-bottom: ", ";\n  }\n"])), function (props) {
  return props.top;
}, function (props) {
  return props.bottom;
}, function (props) {
  return props.gap;
});