import _taggedTemplateLiteral from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject;

import styled from 'styled-components';
import { sizes } from '../theme/sizes';
import { colors } from '../theme/colors';
export var TextDescription = styled.p(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-weight: normal;\n  font-style: ", ";\n  text-align: ", ";\n  font-size: ", ";\n  line-height: ", ";\n  color: ", ";\n"])), function (props) {
  return props.textStyle ? props.textStyle : 'normal';
}, function (props) {
  return props.center ? 'center' : 'start';
}, sizes.textFontSize, sizes.textLineHeight, colors.descriptionForeground);