import _taggedTemplateLiteral from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject;

import styled from 'styled-components';
import { colors } from '../theme/colors';
import { sizes } from '../theme/sizes';
export var HDivider = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 2px;\n  width: 100%;\n  border: 0;\n  border-bottom: ", "\n    solid ", ";\n\n  @media screen and (max-width: 480px) {\n    margin: ", ";\n  }\n"])), function (props) {
  return props.size ? props.size : sizes.dividerSize;
}, function (props) {
  return props.color ? props.color : colors.divider;
}, function (props) {
  return props.noMarginMobile ? '0' : '20px 0';
});