import _taggedTemplateLiteral from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject;

import styled from 'styled-components';
import { colors } from '../theme/colors';
import { sizes } from '../theme/sizes';
export var Text = styled.p(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", ";\n  letter-spacing: ", ";\n  line-height: ", ";\n  font-style: normal;\n  font-weight: ", ";\n  text-transform: ", ";\n  text-align: ", ";\n  word-break: ", ";\n\n  @media screen and (max-width: ", ") {\n    text-align: ", ";\n  }\n"])), function (props) {
  return props.color ? props.color : colors.titleForeground;
}, function (props) {
  return props.size ? props.size : sizes.textFontSize;
}, function (props) {
  return props.letterSpacing ? props.letterSpacing : 'normal';
}, function (props) {
  return props.lineHeight ? props.lineHeight : sizes.textLineHeight;
}, function (props) {
  return props.weight ? props.weight : 'normal';
}, function (props) {
  return props.allCaps ? 'uppercase' : 'inherit';
}, function (props) {
  return props.center ? 'center' : 'inherit';
}, function (props) {
  return props.breakWord ? 'break-word' : 'inherit';
}, sizes.maxWidthMobile, function (props) {
  return props.center ? 'center' : 'start';
});