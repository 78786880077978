import _taggedTemplateLiteral from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject;

import styled from 'styled-components';
import { colors } from './theme/colors';
export var StepperConnector = styled.hr(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: ", ";\n  width: 0;\n  border: 0;\n  border-right: 2px dashed ", ";\n  height: 100%;\n  margin: 0;\n  flex: auto;\n"])), function (props) {
  var _props$position;

  return (_props$position = props.position) !== null && _props$position !== void 0 ? _props$position : 'relative';
}, colors.divider);