import _taggedTemplateLiteral from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import styled from 'styled-components';
import { colors } from './theme/colors';
export var PackageSectionItem = styled.section(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: flex-start;\n  flex-direction: column;\n  width: 100%;\n  height: 128px;\n  padding: 16px;\n  margin-bottom: 8px;\n  background-color: ", ";\n  cursor: pointer;\n"])), colors.sectionBackground);
export var ProductDescription = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  p {\n    font-size: 14px;\n    letter-spacing: 1px;\n    color: ", ";\n    white-space: nowrap;\n    max-width: 230px;\n    margin-right: 4px;\n  }\n"])), colors.descriptionForeground);
export var AllPackagesButton = styled.button(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  height: 48px;\n  padding: 16px;\n  margin-bottom: 8px;\n  background-color: ", ";\n  font-size: 14px;\n  text-transform: uppercase;\n  font-weight: 700;\n\n  &:focus {\n    outline: none;\n  }\n\n  cursor: pointer;\n"])), colors.sectionBackground);
export var ProductSection = styled.section(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: flex-start;\n  flex-direction: column;\n  width: 100%;\n\n  padding: 24px;\n  background-color: ", ";\n  cursor: pointer;\n"])), colors.sectionBackground);
export var ProductItemSection = styled.section(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  width: 100%;\n\n  background-color: ", ";\n  cursor: pointer;\n"])), colors.sectionBackground);
export var ProductImage = styled.img(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  border: 1px solid #e4e9f2;\n"])));