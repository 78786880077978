import _taggedTemplateLiteral from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject;

import styled from 'styled-components';
import { sizes } from '../theme/sizes';
export var ResponsiveVSpacer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  width: ", " !important;\n  height: 100%;\n\n  @media screen and (max-width: ", ") {\n    width: ", " !important;\n  }\n"])), function (props) {
  return props.defaultSpace;
}, sizes.maxWidthMobile, function (props) {
  return props.mobileSpace;
});