import _taggedTemplateLiteral from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject;

import styled from 'styled-components';
import { colors } from '../../theme/colors';
export var Textarea = styled.textarea(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n\n  border-radius: 4px;\n  padding: 8px 16px;\n  width: 100%;\n  max-width: ", ";\n  box-sizing: border-box;\n  height: auto;\n  background-color: ", ";\n  color: ", ";\n  border: 2px solid\n    ", ";\n\n  &:focus {\n    border: 2px solid ", ";\n  }\n"])), function (props) {
  return props.maxWidth ? props.maxWidth : '300px';
}, colors.inputBackground, colors.inputColor, function (props) {
  return props.error ? colors.red : colors.superLightGray;
}, colors.descriptionForeground);