import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import { useTranslation } from 'react-i18next';
export var useRatingTranslation = function useRatingTranslation(formType) {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var getTranslation = function getTranslation(key) {
    var correctKey = [formType, key].filter(Boolean).join('_');
    return t(correctKey);
  };

  return getTranslation;
};