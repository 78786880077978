import _taggedTemplateLiteral from "/home/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject;

import styled from 'styled-components';
import { colors } from '../theme/colors';
import { sizes } from '../theme/sizes';
export var Link = styled.a(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: ", ";\n  text-decoration-line: underline;\n  cursor: pointer;\n  font-size: ", ";\n  line-height: ", ";\n  text-transform: ", ";\n  margin: ", ";\n"])), colors.royal, function (props) {
  return props.size ? props.size : sizes.descriptionFontSize;
}, function (props) {
  return props.lineHeight ? props.lineHeight : sizes.descriptionLineHeight;
}, function (props) {
  return props.allCaps ? 'uppercase' : 'none';
}, function (props) {
  return props.margin ? props.margin : '';
});